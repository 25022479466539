.dropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
}

.dropdown-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
}
.dropdown:empty {
  border: none;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
