@import url("https://use.typekit.net/bur0yty.css");

@font-face {
    font-family: AvenirNext;
    src: url(../fonts/AvenirNext.otf);
}

:root {
    --mypoppies-h-font: apparat, sans-serif;
    --mypoppies-p-font: ingra, sans-serif;
    --mypoppies-button-font: AvenirNext, sans-serif;
    --color-01: #1A1A1A;
    --color-02: #ffffff;
    --color-03: #48414D;
    --color-04: #b09a8c66;
    --color-05: #898792;
    --color-06: #bab07b;
    --color-07: #32332e;
    --color-08: #838e6e;
    --color-09: #6B7750;
    --color-10: #B3B3B3;
    --color-11: #ff0000;
    --color-12: #A9A15D;
}

.row {
    --bs-gutter-x: 3rem;
}

.w_font {
    color: var(--color-02);
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1200px;
    }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}

h1 {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 40px;
    line-height: 50px;
    color: var(--color-01);
}

h2 {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 25px;
    line-height: 35px;
    color: var(--color-01);
}

h3 {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-01);
}

h4 {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    line-height: 28px;
    color: var(--color-01);
}

h5 {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    line-height: 25px;
    color: var(--color-01);
}

h6 {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 10px;
    line-height: 15px;
    color: var(--color-01);
}

body, p {
    font-family: var(--mypoppies-p-font);
    letter-spacing: 0.45px;
    font-size: 15px;
    line-height: 24px;
    color: var(--color-01);
    font-weight: 400;
}

.theme-btn {
    background: var(--color-01);
    border-radius: 0;
    padding: 20px 50px;
    color: var(--color-02);
    text-transform: uppercase;
    font-family: var(--mypoppies-button-font);
    letter-spacing: 2px;
    font-size: 10px;
}

.theme-btn-2 {
    background: var(--color-03);
}

.theme-btn-3 {
    background: var(--color-07);
}

.theme-btn-4 {
    background: transparent;
    color: var(--color-01);
    border: 1px solid;
}

.theme-btn:hover {
    background: var(--color-03);
    color: var(--color-02);
}

.theme-btn-2:hover {
    background: var(--color-01);
}

/***** Header Section *****/
.header_section {
    background-image: url(../images/Mohnkapseln_Header.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.header_section .container .row {
    height: 750px;
}

.header_section_info {
    margin-bottom: 10rem;
}

.header_section_p {
    position: relative;
    padding-left: 80px;
}

.header_section_p:after {
    position: absolute;
    content: '';
    border: 1px;
    width: 45px;
    height: 2px;
    left: 0;
    top: 10px;
    background: var(--color-01);
}

.section_paddding {
    padding: 100px 0;
}

.product_link {
    text-decoration: none;
}

.section_paddding_2 {
    padding: 170px 0;
}

.home_extra_product_section {
    padding-top: 215px;
}

.home_product_section .product_info,
.product__container .product_info {
    display: flex;
    justify-content: space-between;
    margin-top: 15px !important;
    align-items: flex-end !important;
}

.product_link_btn {
    background: var(--color-02);
    border-radius: 0;
    padding: 10px 40px;
    color: var(--color-01);
    text-transform: uppercase;
    font-family: var(--mypoppies-button-font);
    letter-spacing: 2px;
    font-size: 10px;
    border: 1px solid var(--color-01);
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
}

.home_product_section .product_name,
.product__container .product_name {
    font-size: 15px;
    text-align: left;
    min-width: 80%;
    line-height: 1.5;
    margin-bottom: 0;
}

.home_product_section .product_price,
.product__container .product_price {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
}

.home_promotion_section {
    background: url(../images/promotion.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home_promotion_section_title,
.home_promotion_section_text {
    color: var(--color-02);
}

.home_extra_product_col_1 {
    background: var(--color-04);
    padding: 70px 0 70px 60px;
}

.home_extra_product_col_2 {
    background: var(--color-05);
    padding: 70px 0 70px 60px;
}

.home_extra_product_image {
    width: 170px;
    height: 230px;
    object-fit: contain;
}

.home_extra_product_link {
    font-family: var(--mypoppies-h-font);
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
}

.products_page_header {
    background: url(../images/products_header.jpg);
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
}

.products_page_header_row {
    height: 800px;
    align-items: center;
    padding-bottom: 350px;
}

.products_page_header_title {
    color: var(--color-06);
}

.products_page_header_p {
    position: relative;
    padding-left: 80px;
    color: var(--color-06);
}

.products_page_header_p:after {
    position: absolute;
    content: '';
    border: 1px;
    width: 45px;
    height: 2px;
    left: 0;
    top: 10px;
    background: var(--color-06);
}

.poppy_info_row {
    align-items: center;
    margin-top: -130px;
}

.poppy_info_text {
    padding-top: 100px;
}

.poppy_owner_section {
    margin-top: 120px;
}

.poppy_owner_info {
    padding-top: 50px;
}

.poppy_owner_image {
    position: relative;
}

.poppy_owner_title {
    color: var(--color-08);
    position: absolute;
    right: -14%;
    top: 15%;
}

.poppy_pods_section {
    padding: 215px 0;
}

.poppy_pods_info {
    padding: 140px 140px 140px 40px;
}

.poppy_pods_section .row {
    align-items: flex-end;
}

.superfood_section {
    background: url(../images/superfood_bg.jpg);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.superfood_info {
    padding: 140px 140px 340px 40px;
}

.comingsoon_section {
    padding: 215px 0 0;
}

.comingsoon_row_2 {
    margin-top: -80px;
}

.comingsoon_row_2 .col-md-4 {
    margin-bottom: 80px;
}


.shop_product_col {
    margin-top: 100px;
}

.shop_product_col:nth-child(1),
.shop_product_col:nth-child(2),
.shop_product_col:nth-child(3) {
    margin-top: 0;
}

.product_image_new {
    background: var(--color-09);
    position: absolute;
    color: var(--color-02);
    line-height: 1;
    margin-bottom: 0;
    padding: 5px 10px;
    font-weight: 500;
    right: 20px;
    top: 20px;
}


.product_image_div {
    position: relative;
}

.product_filter_col {
    justify-content: space-between;
}

.product_filter_col h6 {
    font-weight: 500;
    opacity: 0.7;
    cursor: pointer;
}

.product_filter_col .active {
    opacity: 1;
}

.shop_slider_img {
    height: 650px;
    width: 100%;
    object-fit: cover;
}

.onlineshopslider_section {
    margin-bottom: 160px;
}

.slick-dots {
    text-align: left;
    top: 50%;
    left: -5%;
    bottom: auto;
    transform: translateY(-50%);
    z-index: 0;
    display: inline;
    width: auto;
}

.slick-dots ul {
    padding-left: 0;
}

.slick-dots li {
    display: block;
    width: auto;
    height: auto;
    margin: 30px 0;
    font-family: var(--mypoppies-button-font);
    opacity: 0.7;
}

.slick-dots li.slick-active {
    opacity: 1;
    font-weight: 600;
}

.slick-vertical .slick-slide {
    border: none;
}

.single_product_name {
    margin-bottom: 90px;
}

.stock {
    font-family: var(--mypoppies-button-font);
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.instock {
    color: var(--color-08);
}

.soldout {
    color: var(--color-11);
}

.single_product_variations_price {
    font-family: var(--mypoppies-h-font);
    font-weight: 600;
}

.single_product_variations_div {
    margin: 0 10px;
}

.single_product_variations_div:first-child {
    margin-left: 0;
}

.single_product_variations_div:last-child {
    margin-right: 0;
}

.single_product_image {
    width: 100%;
    height: 650px;
    object-fit: cover;
    object-position: center;
}

.single_product_variations_title {
    padding: 20px;
    text-align: center;
    display: block;
    font-size: 10px;
    font-family: var(--mypoppies-button-font);
    text-transform: uppercase;
    border: 1px solid var(--color-01);
    line-height: 1.5;
    opacity: 0.4;
}

.single_product_breadcrumbs span {
    font-size: 10px;
    font-family: var(--mypoppies-button-font);
    text-transform: uppercase;
}

.single_product_breadcrumbs_title {
    opacity: 0.5;
}

.single_product_breadcrumbs_spac {
    padding: 0 7px;
    opacity: 0.5;
}

.product_info_section {
    margin-bottom: 100px;
}

.available_img {
    height: 75px;
    margin-right: 30px;
}

.available_img.amazon_logo_img {
    height: 50px;
    vertical-align: bottom;
}

.contact_header_section {
    background-image: url(../images/contact_bg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contact_header_section .container .row {
    height: 750px;
}

.contact_header_section_info {
    margin-bottom: 15rem;
}

.contact_header_section_p {
    position: relative;
    padding-left: 80px;
}

.contact_header_section_p:after {
    position: absolute;
    content: '';
    border: 1px;
    width: 45px;
    height: 2px;
    left: 0;
    top: 10px;
    background: var(--color-01);
}

.contact_form_section {
    padding: 160px 0;
}

.form_label {
    margin-top: 20px;
    margin-bottom: 60px;
}

.form-control.form_input {
    border: none;
    border-bottom: 1px solid var(--color-01);
    border-radius: 0;
}

textarea.form-control.form_input {
    resize: none;
    height: 130px;
}

.form-control.form_input:focus {
    box-shadow: none;
}

.footer_section {
    padding: 80px 0;
}

.footer_section {
    background: var(--color-01);
}

.footer_title {
    color: var(--color-10);
    margin-bottom: 30px;
}

ul.footer_link_ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer_link_li a,
.footer_contact_li {
    font-family: var(--mypoppies-h-font);
    font-size: 15px;
    color: var(--color-10);
    text-decoration: none;
    line-height: 1.5;
}

ul.footer_contact_ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.footer_social_ul {
    padding: 0;
    margin: 20px 0 0 0;
}

li.footer_social_li {
    display: inline-block;
    margin-right: 15px;
}

.footer_social_icon {
    width: 25px;
}

.footer_copyright_text {
    font-family: var(--mypoppies-h-font);
    font-size: 15px;
    color: var(--color-10);
    text-decoration: none;
    line-height: 1.5;
    margin-bottom: 0;
}

.footer_newsletter_input {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--color-10);
    border-radius: 0;
    color: var(--color-02);
}

.footer_newsletter_label {
    color: var(--color-10);
    margin-top: 5px;
}

.footer_newsletter {
    margin-bottom: 50px;
}

.footer_newsletter_input:focus {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid var(--color-10);
    color: var(--color-02);
}

.login_section {
    padding-top: 70px;
}

.login_formlabel {
    margin-top: 10px;
    margin-bottom: 30px;
}

.page_title {
    margin-bottom: 50px;
}

.link_text {
    color: var(--color-01);
}

.account_sidemenu a {
    display: block;
    font-family: var(--mypoppies-h-font);
    color: var(--color-01);
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 15px;
    opacity: 0.8;
}

.account_sidemenu a.active {
    font-weight: 600;
    opacity: 1;
}

.cart_table thead {
    font-family: var(--mypoppies-h-font);
    text-transform: uppercase;
    font-size: 15px;
}

.cart_table tbody {
    border-top: 1px solid;
    border-bottom: 1px solid;
}

.qtybtn {
    border: 1px solid;
    padding: 1px 9px;
    display: inline-block;
    width: 28px;
    height: 28px;
    text-align: center;
}

.productqty {
    text-align: center;
    width: 80px;
    border: none;
}

.cart_table tr th {
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    border-color: #000;
}

.cart_table tbody tr:first-child td {
    padding-top: 10px;
}

.cart_table tbody tr:last-child td {
    padding-bottom: 10px;
}

.cart_table tbody tr td {
    padding-bottom: 10px;
    vertical-align: middle;
}

.coupon_input {
    border: 1px solid var(--color-01) !important;
    border-radius: 0 !important;
    padding: 20px !important;
    margin-bottom: 15px !important;
    display: inline-block;
    width: 57%;
    font-size: 13px;
    text-transform: uppercase;
}

.coupon_btn {
    display: inline-block;
    width: 40%;
    padding: 20px 0;
    margin-left: 10px;
}

label.shipping_label {
    display: block;
    margin-bottom: 15px;
}

label.shipping_label:last-child {
    margin-bottom: 0;
}

label.shipping_label input {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    vertical-align: top;
    border: 1px solid var(--color-01);
    border-radius: 0;
    background: var(--color-02) no-repeat center center;
}

label.shipping_label input:checked {
    background-image: url(../images/checkbox.svg);
}

.checkout_form_label {
    margin-top: 10px;
    margin-bottom: 40px;
}

.checkout_hr {
    margin: 20px 0;
}

.checkout_img {
    height: 30px;
    margin-right: 30px;
}

.product_price {
    font-weight: 300;
}

.bottom_hr {
    margin: 25px 20px;
}

.shipping_label span {
    display: inline-block;
}

.variation_select {
    border-radius: 0;
    padding: 18px 20px;
    color: var(--color-01);
    text-transform: uppercase;
    font-family: var(--mypoppies-button-font);
    letter-spacing: 2px;
    font-size: 15px;
    border: 1px solid var(--color-01);
}

.form_qty_input {
    border-radius: 0;
    padding: 18px 20px;
    color: var(--color-01);
    text-transform: uppercase;
    font-family: var(--mypoppies-button-font);
    letter-spacing: 2px;
    font-size: 15px;
    border: 1px solid var(--color-01);
    text-align: center;
}

.w-35 {
    width: 35%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.w-15 {
    width: 15%;
}

@media (max-width: 700px) {
    h1 {
        font-size: 25px;
        line-height: 35px;
    }

    h2 {
        font-size: 20px;
        line-height: 30px;
    }

    h3 {
        font-size: 18px;
        line-height: 28px;
    }

    .header_section_p {
        padding-left: 0;
        padding-top: 20px;
        margin-bottom: 0 !important;
    }

    .theme-btn {
        padding: 15px 30px;
    }

    .header_section_info {
        margin-bottom: 0 !important;
    }

    .row {
        --bs-gutter-x: 1.5rem;
    }

    .header_section .container .row {
        height: 450px;
    }

    .header_section {
        background-position: 60% 0;
        background-size: initial;
    }

    .section_paddding {
        padding: 30px 0;
    }

    .home_product_section .shop_product_col {
        margin-top: 30px;
    }

    .home_product_section h2.mb-4 {
        margin-bottom: 0 !important;
    }

    .home_product_section .shop_product_col:first-child {
        margin-top: 15px;
    }

    .home_product_section .product_image_div img,
    .product__container .product_image_div img {
        height: 300px;
        width: 100%;
        object-fit: cover;
    }

    .home_promotion_section {
        padding: 110px 0;
    }

    .home_extra_product_section {
        display: none;
    }

    .home_extra_product_col_1, .home_extra_product_col_2 {
        padding: 30px;
        margin-bottom: 30px;
    }

    .home_extra_product_image {
        width: 100px;
    }

    .footer_section {
        margin-top: 0;
    }

    .products_page_header_row {
        height: 600px;
        padding-bottom: 150px;
    }

    .poppy_info_row {
        margin-top: 50px;
    }

    .poppy_info_text {
        padding-top: 0;
    }

    .poppy_info_text .mb-5.pb-5 {
        padding-bottom: 3rem !important;
        margin-bottom: 0 !important;
    }

    .poppy_owner_section {
        margin-top: 80px;
    }

    .poppy_owner_title {
        right: auto;
        top: auto;
        position: relative;
        margin-top: 20px;
    }

    .poppy_owner_info {
        padding-top: 10px;
    }

    .poppy_pods_section {
        padding: 100px 0;
    }

    .poppy_pods_info {
        padding: 30px 0 60px;
    }

    .poppy_owner_section .px-4 {
        padding: 0 !important;
    }

    .poppy_pods_section .mt-5 {
        margin-top: 2rem !important;
    }

    .superfood_info {
        padding: 100px 50px;
        z-index: 99999;
        position: relative;
    }

    .superfood_section:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000057;
        z-index: 0;
    }

    .superfood_section {
        position: relative;
        z-index: 999;
    }

    .comingsoon_section {
        padding: 100px 0 0;
    }

    .comingsoon_row_2 {
        margin-top: 50px;
    }

    .comingsoon_row_1 .col-md-4 {
        order: 2;
    }

    .comingsoon_row_1 .btn,
    .comingsoon_row_2 .btn {
        margin-top: 1.5rem !important;
    }

    .comingsoon_row_2 .col-md-4 {
        margin-bottom: 0;
    }

    .shop_slider_img {
        height: 300px;
    }

    .slick-dots {
        position: relative;
    }

    .slick-dots li {
        margin: 0px 10px;
        display: inline-block;
    }

    .slick-dots ul {
        text-align: center;
    }

    .onlineshop_products_section .shop_product_col {
        margin-top: 30px;
    }

    .onlineshop_products_section h2.mb-5 {
        margin-bottom: 0rem !important;
        margin-top: 2rem !important;
    }

    .onlineshopslider_section {
        margin-bottom: 40px;
    }

    .single_product_image {
        height: 400px;
        margin-bottom: 30px;
    }

    .single_product_name {
        margin-bottom: 20px;
    }

    .related_section .shop_product_col {
        margin-top: 30px;
    }

    .related_section .mb-4 {
        margin-bottom: 0 !important;
    }

    .product_info_section {
        margin-bottom: 100px;
    }

    .contact_form_section {
        padding: 100px 0;
    }

    h2.contact_form_section_h1.mb-5.pb-3 {
        margin-bottom: 2rem !important;
    }

    .contact_form_section .form_label {
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .contact_header_section .container .row {
        height: 600px;
    }

    .contact_header_section_info {
        margin-bottom: 5rem;
    }

    .contact_header_section {
        background-position: top right !important;
    }

    .available_img {
        margin: 20px 0;
        width: 100%;
        object-fit: contain;
    }

    .footer_section {
        padding: 40px 0;
    }

    .footer_title {
        margin-bottom: 10px;
    }

    .footer_copyright_text {
        margin-top: 50px;
        font-size: 11px;
    }

    .footer_section .footer_social_ul {
        display: none;
    }

    .product_filter_col {
        display: block !important;
        text-align: right;
    }

    .login_section {
        padding-top: 40px;
    }

    .page_title {
        margin-bottom: 20px;
    }

    .checkout_form_label {
        margin-bottom: 20px;
    }

    .checkout_bottom_text {
        margin-top: 30px;
        font-size: 14px !important;
    }

    .checkout_bottom_text p {
        font-size: 14px !important;
    }

    .condition_label {
        display: flex !important;
    }

    .condition_label input {
        min-width: 22px;
        min-height: 22px;
    }

    .m_order_1 {
        order: 1;
    }

    .m_order_2 {
        order: 2;
    }

    .productqty {
        width: 30px;
    }

    .qtybtn {
        width: 22px;
        height: 22px;
        padding: 0;
        line-height: 1.5;
    }

    .cart_table tbody tr td {
        font-size: 11px;
    }
}